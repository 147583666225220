.header-desktop
{
    left:0px;
    right:300px;
}
.account-item > .content > a:after
{
    margin:0px;
    margin-right:5px;
}
.au-btn-icon i , .navbar-sidebar .navbar__list li a i
{
    margin:0px;
    margin-left: 5px;
}
.menu-sidebar
{
    left:initial;
    right: 0px;
}
.navbar-sidebar .navbar__list li a span
{
    float:left;
}

.page-container
{
    padding:0px;
    padding-right: 300px;
}
.images-list
{
    height:300px;
    overflow: auto;
}
.images-list > div
{
    display: block;
    width: 24.25%;
    float:right;
    margin: 2px 0 10px 1%;
    height: auto;
    line-height: 0px;
}
.images-list > div:nth-child(4n+4)
{
    margin: 2px 0 2px 0;
}