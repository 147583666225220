
@import url('https://fonts.googleapis.com/css2?family=Markazi+Text&family=Roboto&display=swap');


* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

ul , .table {
    margin: 0;
}

button,
input[type='button'] {
    cursor: pointer;
}

button:focus,
input:focus,
textarea:focus {
    outline: none;
}

input, textarea {
    border: none;
}

button {
    border: none;
    background: none;
}

img {
    max-width: 100%;
    height: auto;
}

p {
    margin: 0;
}

.table-responsive {
    padding-right: 1px;
}

.card {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.btn {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
.table td, .table th
{
    padding:0.5em;
}
.table td:nth(1) , .table th:nth(1)
{
    border-top:0px
}
/* ----- Reset ----- */
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #333333;
    font-weight: 700;
    margin: 0;
    line-height: 1.2;
}

h1 {
    font-size: 36px;
}

h2 {
    font-size: 30px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 18px;
}

h5 {
    font-size: 15px;
}

h6 {
    font-size: 13px;
}

blockquote {
    margin: 0;
}

strong {
    font-weight: 700;
}

.no-gutters
{
    margin:0 !important;
}

.show-dropdown .js-dropdown {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}



body {
    /* font-family: "Droid Arabic Naskh" , "Poppins", sans-serif; */
    font-family: 'Markazi Text', serif;

    font-weight: 400;
    font-size: 23px;
    line-height: 1.625;
    color: #666;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background:#f8f9fe
}

.menu-sidebar {
    width: 300px;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    overflow-y: auto;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, .15) !important;
    z-index:999;
    display: none;
}

.menu-sidebar .logo {
    height: 75px;
    padding: 0 35px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    
    position: relative;
    z-index: 3;
}

.menu-sidebar .navbar__list .navbar__sub-list {
    display: none;
    padding-left: 34px;
}

.menu-sidebar .navbar__list .navbar__sub-list li a {
    padding: 11.5px 0;
}

.menu-sidebar__content {
    position: relative;
    height: -webkit-calc(100vh - 75px);
    height: -moz-calc(100vh - 75px);
    height: calc(100vh - 75px);
}

.navbar-sidebar {
    padding: 35px;
    padding-bottom: 0;
}

.navbar-sidebar .navbar__list li a {
    display: block;
    color: #555;
    font-size: 20px;
    padding: 15px 0;
}

.navbar-sidebar .navbar__list li a i {
    margin-right: 5px;
}
.navbar-sidebar .navbar__list li a span {
    float:right;
}

.navbar-sidebar .navbar__list li a:hover {
    color: #4272d7;
}

.navbar-sidebar .navbar__list li.active > a {
    color: #4272d7;
}


.page-wrapper 
{
    overflow: hidden;
}

.page-container 
{
    padding-left: 300px;
    color: #525f7f;
}


/* ----- Header ----- */
.header-desktop {
    position: fixed;
    top: 0;
    right: 0;
    left: 300px;
    height: 75px;
    z-index: 3;
    background:#f8f9fe;
}
.header-desktop.scroll
{
    background: #f5f5f5;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
}

.header-desktop div.toggle
{
    display: none;
}
.header-desktop .section__content {
    overflow: visible;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}




.header-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 991px) {
    

    .header-wrap .mess-dropdown {
        left: 0;
    }

    .header-wrap .mess-dropdown::before {
        left: 0;
    }

    .header-wrap .notifi-dropdown {
        left: -83px;
    }

    .header-wrap .notifi-dropdown::before {
        left: 79px;
    }
}

.header-button {
    display: flex;
    align-items: center;
}

.section__content
{
    padding: 0 30px;
}


.account-wrap {
    position: relative;
    width: 100%;
}

.account-item {
    cursor: pointer;
}

.account-item .image {
    width: 45px;
    height: 45px;
    float: left;   
    position: relative;
}

.account-item .image > img {
    width: 100%;
    border-radius: 50%;
}

.account-item .image > .alert-num
{
    position: absolute;
    display: inline-block;
    top: 2px;
    right: -5px;
    height: 15px;
    width: 15px;
    line-height: 15px;
    text-align: center;
    background: #ff4b5a;
    color: #fff;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    font-size: 12px;
}

.account-item > .content {
    margin-left: 45px;
    padding: 9px 0;
    padding-left: 12px;
}

.account-item > .content > a {
    color: #333;
    text-transform: capitalize;
    font-weight: 500;
}

.account-item > .content > a:after {
    font-family: "FontAwesome";
    content: "\f078";
    display: inline-block;
    font-size: 16px;
    margin-left: 5px;
}

.account-item > .content > a:hover {
    color: #666;
}

.account-dropdown {
    min-width: 190px;
    position: absolute;
    top: 58px;
    right: 0;
    background: #fff;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    transform: scale(0);
    transition: all 0.4s ease;
    transform-origin: right top;
    z-index: 3;
    border-radius: 5px;
    display: none;
}


.account-dropdown:after {
    content: '';
    display: block;
    width: 19px;
    height: 19px;
    border-bottom: 9px solid #fff;
    border-top: 9px solid transparent;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    position: absolute;
    top: -18px;
    right: 33px;
}

.account-dropdown__item a {
    display: block;
    color: #333;
    padding: 10px 20px;
    font-size: 14px;
}

.account-dropdown__item a:hover {
    background: #4272d7;
    color: #fff;
}

.account-dropdown__item a i {
    line-height: 1;
    margin-right: 20px;
    font-size: 18px;
    vertical-align: middle;
}

.account-dropdown__body {
    padding: 5px 0;
}

.account-dropdown__footer {
   background: #f2f2f2;
}

.account-dropdown__footer a {
    display: block;
    color: #333;
    padding: 15px 25px;
    font-size: 14px;
}

.account-dropdown__footer a:hover {
    background: #4272d7;
    color: #fff;
}

.account-dropdown__footer a i {
    line-height: 1;
    margin-right: 20px;
    font-size: 18px;
    vertical-align: middle;

}
.form-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    background: white;
    border: 1px solid #e5e5e5;
    border-radius: 52px;
    overflow: hidden;
}

@media (max-width: 991px) {
    .form-header {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}



.main-content {
    padding-top: 116px;
    min-height: 100vh;
}

.main-content .row
{
    margin:15px -15px ;
}



/* ----- Input ----- */
.au-input {
    line-height: 43px;
    font-size: 14px;
    padding: 0 17px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
  
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    transition: all 0.5s ease;
}


.au-input--full {
    width: 100%;
}

.au-input--h65 {
    line-height: 63px;
    font-size: 16px;
    color: #808080;
}

.au-input--w300 {
    min-width: 300px;
}

.au-input--w435 {
    min-width: 435px;
}

@media (max-width: 767px) {
    .au-input--w435 {
        min-width: 230px;
    }
}

.au-form-icon {
    position: relative;
}

.au-form-icon .au-input {
    padding-right: 80px;
}

.au-form-icon--sm {
    position: relative;
}

.au-form-icon--sm .au-input {
    padding-right: 43px;
}

.au-input-icon {
    position: absolute;
    top: 1px;
    right: 12px;
    width: 63px;
    height: 63px;
    line-height: 63px;
    text-align: center;
    display: block;
}

.au-input-icon i {
    font-size: 30px;
    color: #808080;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.au-card , .au-card-grid {

    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 5px;
    background: #fff;
    overflow: hidden;
}

.au-card-grid {
    margin-left: 15px;
    margin-bottom: 15px;
    min-height: 200px;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.no-logo {
    display: flex;
}

.au-card--list
{
    background: rgba(82, 95, 127, 0.1);
}
.au-card--list > .table
{
    border-radius: 5px;
    overflow: hidden;
    background:white;
}

.au-card--border {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.au-card--border .au-card-title {
    -webkit-border-top-left-radius: 3px;
    -moz-border-radius-topleft: 3px;
    border-top-left-radius: 3px;
    -webkit-border-top-right-radius: 3px;
    -moz-border-radius-topright: 3px;
    border-top-right-radius: 3px;
}

.au-card--border .au-card-title .bg-overlay {
    -webkit-border-top-left-radius: 3px;
    -moz-border-radius-topleft: 3px;
    border-top-left-radius: 3px;
    -webkit-border-top-right-radius: 3px;
    -moz-border-radius-topright: 3px;
    border-top-right-radius: 3px;
}

.au-card-bordered {
    border: 1px solid #e5e5e5;
    background: #fff;
    padding: 40px;
    padding-top: 42px;
    padding-right: 55px;
    margin-bottom: 40px;
}

.au-card--bg-blue {
    background-image: -moz-linear-gradient(90deg, #396afc 0%, #2948ff 100%);
    background-image: -webkit-linear-gradient(90deg, #396afc 0%, #2948ff 100%);
    background-image: -ms-linear-gradient(90deg, #396afc 0%, #2948ff 100%);
}

.au-card-top-countries {
    padding: 40px;
    padding-top: 25px;
    padding-bottom: 29px;
}

.au-card--no-shadow {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.au-card--no-pad {
    padding: 0;
}

.au-card-title {
    position: relative;
    padding: 40px;
    padding-top: 45px;
    padding-bottom: 47px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-border-top-left-radius: 10px;
    -moz-border-radius-topleft: 10px;
    border-top-left-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    -moz-border-radius-topright: 10px;
    border-top-right-radius: 10px;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
}

.au-card-title .bg-overlay {
    -webkit-border-top-left-radius: 10px;
    -moz-border-radius-topleft: 10px;
    border-top-left-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    -moz-border-radius-topright: 10px;
    border-top-right-radius: 10px;
}

.au-card-title h3 {
    position: relative;
    z-index: 2;
    color: #fff;
    font-weight: 400;
}

.au-card-title h3 i {
    color: #fff;
    font-size: 24px;
    margin-right: 12px;
}

.au-input--xl {
    min-width: 935px;
}

@media (max-width: 1600px) {
    .au-input--xl {
        min-width: 350px;
    }
}

@media (max-width: 991px) {
    .au-input--xl {
        min-width: 350px;
    }
}

@media (max-width: 767px) {
    .au-input--xl {
        min-width: 150px;
    }
}

.au-btn--submit {
    position: relative;
    right: 0;
    min-width: 65px;
    line-height: 43px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background: white;
    margin-left: -3px;
}

.au-btn--submit:hover >i {
    color : #3868cd;
}

.au-btn--submit > i {
    font-size: 20px;
    color: #a9b3c9;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.overview-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.overview-wrap h2
{

}
.overview-wrap button
{

}
/*-----------------------------------------------------*/
/*                   COMPONENTS                        */
/*-----------------------------------------------------*/
/* ----- Buttons----- */
.au-btn {
    line-height: 45px;
    padding: 0 35px;
    text-transform: uppercase;
    color: #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    cursor: pointer;
}

.au-btn:hover {
    color: #fff;
    background: #3868cd;
}



.au-btn--block {
    display: block;
    width: 100%;
}

.au-btn-icon i {
    vertical-align: baseline;
    margin-right: 5px;
}

.au-btn--blue {
    background: #4272d7;
}

.au-btn--green {
    background: #63c76a;
}

.au-btn--green:hover {
    background: #59bd60;
}

.au-btn-plus {
    position: absolute;
    height: 45px;
    width: 45px;
    background: #63c76a;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    bottom: -22.5px;
    right: 45px;
    z-index: 3;
}

.au-btn-plus i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 15px;
    font-weight: 500;
    color: #fff;
}

.au-btn-plus:hover {
    background: #59bd60;
}

.au-btn-load {
    background: #808080;
    padding: 0 40px;
    font-size: 15px;
    color: #fff;
}

.au-btn-load:hover {
    background: #767676;
}

.au-btn-filter {
    font-size: 14px;
    color: #808080;
    background: #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.03);
    -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.03);
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.03);
    padding: 0 15px;
    line-height: 40px;
    text-transform: capitalize;
}

.au-btn-filter i {
    margin-right: 5px;
}

.au-btn--small {
    padding: 0 20px;
    line-height: 40px;
    font-size: 14px;
}

.info-box {
    display: block;
    min-height: 90px;
    background: #fff;
    width: 100%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}
.info-box small {
    font-size: 14px;
}
.info-box .progress {
    background: rgba(0, 0, 0, 0.2);
    margin: 5px -10px 5px -10px;
    height: 2px;
}
.info-box .progress,
.info-box .progress .progress-bar {
    border-radius: 0;
}
.info-box .progress .progress-bar {
    background: #fff;
}
.info-box-icon {
    border-top-right-radius: 2px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 2px;
    display: block;
    float: right;
    height: 90px;
    width: 90px;
    text-align: center;
    font-size: 45px;
    line-height: 90px;
    background: rgba(0, 0, 0, 0.2);
}
.info-box-icon > img {
    max-width: 100%;
}
.info-box-content {
    padding: 5px 10px;
    margin-right: 90px;
}
.info-box-number {
    display: block;
    font-weight: bold;
    font-size: 18px;
}
.info-box-text {
    display: block;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.info-box-text
{
    text-transform: uppercase;
}
.info-box-more
{
    display: block;
}

/* Background*/
.bg-white {
    background: #fff;
}

.bg-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
}

.bg-overlay--blue {
    background: rgba(49, 89, 253, 0.9);
}

.bg-c1 {
    background: #00ad5f;
}

.bg-c2 {
    background: #fa4251;
}

.bg-c3 {
    background: #ff8300;
}

.bg-flat-color-1 {
    background: #20a8d8;
}

.bg-flat-color-2 {
    background: #63c2de;
}

.bg-flat-color-3 {
    background: #ffc107;
}

.bg-flat-color-4 {
    background: #f86c6b;
}

.bg-flat-color-5 {
    background: #4dbd74;
}
/*991 or less*/
@media (max-width: 991px) {
    .section__content
    {
        padding: 0px;
    }
    .page-container {
        position: relative;
        padding: 0 !important;
    }
    .header-desktop
    {
        left: 0;
        right: 0 !important;
    }
    .header-button {
     
        justify-content: space-between;
    }
    .header-desktop div.toggle
    {
        display: flex;
    }
}

@media (max-width: 600px) {
    .account-item > .content > a
    {
        display: none;
    }

}

@media(max-width: 475px) {
    .form-header
    {
        margin: auto 10px;
    }
}




.has-line-data {
    width: 100%;
}




.table-data thead tr td {
    font-weight: 600;
    color: #808080;
    text-transform: uppercase;
}
.table-data .table tr {
    border-top: none;
    border-bottom: 1px solid #f2f2f2;
   
}
.table-data .table td {
    border-top: none;
    border-bottom: 1px solid #f2f2f2;
    padding-top: 23px;
    padding-bottom: 33px;
    padding-left: 40px;
    padding-right: 10px;
}

.table-data .table tr td:last-child {
    padding-right: 24px;
    border-bottom: none;
}

.table-data tbody tr:hover td .more {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

.table-data__info h6 {
    color: #808080;
    text-transform: capitalize;
    font-weight: 400;
}

.table-data__info span a {
    color: #999;
}

.table-data__info span a:hover {
    color: #666;
}

.centered-list {
    justify-content: center;
    justify-self: center;
}

.show {
    background: rgba(150,150,150,0.5) ;
    display: inline-block;
}

.navbar__list svg {
    margin: 0px 5px; 
}

.breadcrumb {
    background: none;
    padding: 0px;
}
div.content{
    text-align: center;
}
div.content img{
    clear:both;
    margin: 0px auto;
}
div.content span {
    width:100%;
    clear:both;
    float:left ;
}