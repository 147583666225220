html {
    direction: rtl;
}

body {
    direction: rtl;
    text-align: right;
}

dd {
    margin-right: 0;
}

caption {
    text-align: right;
}

.list-unstyled {
    padding-right: 0;
}

.list-inline {
    padding-right: 0;
}

.list-inline-item:not(:last-child) {
    margin-right: 0;
    margin-left: 0.5rem;
}

.offset-1 {
    margin-right: 8.333333%;
    margin-left: 0;
}

.offset-2 {
    margin-right: 16.666667%;
    margin-left: 0;
}

.offset-3 {
    margin-right: 25%;
    margin-left: 0;
}

.offset-4 {
    margin-right: 33.333333%;
    margin-left: 0;
}

.offset-5 {
    margin-right: 41.666667%;
    margin-left: 0;
}

.offset-6 {
    margin-right: 50%;
    margin-left: 0;
}

.offset-7 {
    margin-right: 58.333333%;
    margin-left: 0;
}

.offset-8 {
    margin-right: 66.666667%;
    margin-left: 0;
}

.offset-9 {
    margin-right: 75%;
    margin-left: 0;
}

.offset-10 {
    margin-right: 83.333333%;
    margin-left: 0;
}

.offset-11 {
    margin-right: 91.666667%;
    margin-left: 0;
}

@media (min-width: 576px) {
    .offset-sm-0 {
        margin-right: 0;
    }
    .offset-sm-1 {
        margin-right: 8.333333%;
        margin-left: 0;
    }
    .offset-sm-2 {
        margin-right: 16.666667%;
        margin-left: 0;
    }
    .offset-sm-3 {
        margin-right: 25%;
        margin-left: 0;
    }
    .offset-sm-4 {
        margin-right: 33.333333%;
        margin-left: 0;
    }
    .offset-sm-5 {
        margin-right: 41.666667%;
        margin-left: 0;
    }
    .offset-sm-6 {
        margin-right: 50%;
        margin-left: 0;
    }
    .offset-sm-7 {
        margin-right: 58.333333%;
        margin-left: 0;
    }
    .offset-sm-8 {
        margin-right: 66.666667%;
        margin-left: 0;
    }
    .offset-sm-9 {
        margin-right: 75%;
        margin-left: 0;
    }
    .offset-sm-10 {
        margin-right: 83.333333%;
        margin-left: 0;
    }
    .offset-sm-11 {
        margin-right: 91.666667%;
        margin-left: 0;
    }
}

@media (min-width: 768px) {
    .offset-md-0 {
        margin-right: 0;
    }
    .offset-md-1 {
        margin-right: 8.333333%;
        margin-left: 0;
    }
    .offset-md-2 {
        margin-right: 16.666667%;
        margin-left: 0;
    }
    .offset-md-3 {
        margin-right: 25%;
        margin-left: 0;
    }
    .offset-md-4 {
        margin-right: 33.333333%;
        margin-left: 0;
    }
    .offset-md-5 {
        margin-right: 41.666667%;
        margin-left: 0;
    }
    .offset-md-6 {
        margin-right: 50%;
        margin-left: 0;
    }
    .offset-md-7 {
        margin-right: 58.333333%;
        margin-left: 0;
    }
    .offset-md-8 {
        margin-right: 66.666667%;
        margin-left: 0;
    }
    .offset-md-9 {
        margin-right: 75%;
        margin-left: 0;
    }
    .offset-md-10 {
        margin-right: 83.333333%;
        margin-left: 0;
    }
    .offset-md-11 {
        margin-right: 91.666667%;
        margin-left: 0;
    }
}

@media (min-width: 992px) {
    .offset-lg-0 {
        margin-right: 0;
    }
    .offset-lg-1 {
        margin-right: 8.333333%;
        margin-left: 0;
    }
    .offset-lg-2 {
        margin-right: 16.666667%;
        margin-left: 0;
    }
    .offset-lg-3 {
        margin-right: 25%;
        margin-left: 0;
    }
    .offset-lg-4 {
        margin-right: 33.333333%;
        margin-left: 0;
    }
    .offset-lg-5 {
        margin-right: 41.666667%;
        margin-left: 0;
    }
    .offset-lg-6 {
        margin-right: 50%;
        margin-left: 0;
    }
    .offset-lg-7 {
        margin-right: 58.333333%;
        margin-left: 0;
    }
    .offset-lg-8 {
        margin-right: 66.666667%;
        margin-left: 0;
    }
    .offset-lg-9 {
        margin-right: 75%;
        margin-left: 0;
    }
    .offset-lg-10 {
        margin-right: 83.333333%;
        margin-left: 0;
    }
    .offset-lg-11 {
        margin-right: 91.666667%;
        margin-left: 0;
    }
}

@media (min-width: 1200px) {
    .offset-xl-0 {
        margin-right: 0;
    }
    .offset-xl-1 {
        margin-right: 8.333333%;
        margin-left: 0;
    }
    .offset-xl-2 {
        margin-right: 16.666667%;
        margin-left: 0;
    }
    .offset-xl-3 {
        margin-right: 25%;
        margin-left: 0;
    }
    .offset-xl-4 {
        margin-right: 33.333333%;
        margin-left: 0;
    }
    .offset-xl-5 {
        margin-right: 41.666667%;
        margin-left: 0;
    }
    .offset-xl-6 {
        margin-right: 50%;
        margin-left: 0;
    }
    .offset-xl-7 {
        margin-right: 58.333333%;
        margin-left: 0;
    }
    .offset-xl-8 {
        margin-right: 66.666667%;
        margin-left: 0;
    }
    .offset-xl-9 {
        margin-right: 75%;
        margin-left: 0;
    }
    .offset-xl-10 {
        margin-right: 83.333333%;
        margin-left: 0;
    }
    .offset-xl-11 {
        margin-right: 91.666667%;
        margin-left: 0;
    }
}

.form-check {
    padding-right: 1.25rem;
    padding-left: 0;
}

.form-check-inline {
    padding-right: 0;
    margin-left: 0.75rem;
    margin-right: 0;
}
.form-check-input {
    margin-left: 0;
    margin-right: -1.25rem;
}
.form-check-inline .form-check-input {
  margin-left: 0.3125rem;
  margin-right: 0;
}

@media (min-width: 576px) {
    .form-inline .form-check-label {
        padding-right: 0;
    }
    .form-inline .form-check-input {
        margin-left: 0.25rem;
        margin-right: 0;
    }
}

.dropdown-toggle::after {
    margin-right: 0.255em;
    margin-left: 0;
}

.dropdown-toggle:empty::after {
    margin-right: 0;
}

.dropdown-menu {
    right: 0;
    left: initial;
    float: right;
    text-align: right;
}

.dropright .dropdown-menu {
    left: auto;
    right: 100%;
    margin-left: 0;
    margin-right: 0.125rem;
}

.dropright .dropdown-toggle::after {
    margin-left: 0;
    margin-right: 0.255em;
    border-left: 0;
    border-right: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-right: 0;
}

.dropleft .dropdown-menu {
    left: 100%;
    right: auto;
    margin-right: 0;
    margin-left: 0.125rem;
}

.dropleft .dropdown-toggle::after {
    margin-left: 0;
    margin-right: 0.255em;
}

.dropleft .dropdown-toggle::before {
    border-right: 0.3em solid;
    border-left: 0;
}

.dropup .dropdown-toggle::after {
    margin-right: 0.255em;
    margin-left: 0;
}

.dropup .dropdown-toggle:empty::after {
    margin-right: 0;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
    margin-right: -1px;
    margin-left: 0;
}

.btn-group > .btn:first-child {
    margin-right: 0;
}


.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-group > .btn-group {
    float: right;
}

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn + .dropdown-toggle-split {
    padding-right: 0.5625rem;
    padding-left: 0.5625rem;
}

.btn + .dropdown-toggle-split::after {
    margin-right: 0;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
    margin-right: 0;
}

.input-group-btn:first-child > .btn + .btn {
    margin-right: 0;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: 0;
  margin-right: -1px;
}

.input-group-prepend {
  margin-right: 0;
  margin-left: -1px;
}

.input-group-append {
    margin-left: 0;
    margin-right: -1px;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
    margin-left: 0;
    margin-right: -1px;
}

.custom-control {
    padding-right: 1.5rem;
    padding-left: 0;
    margin-left: 1rem;
    margin-right: 0;
}

.custom-control-indicator {
    right: 0;
    left: initial;
}

.custom-control-label::before {
    right: -1.5rem;
    left: 0;
}

.custom-control-label::after {
    right: -1.5rem;
    left: 0;
}


.custom-file-control::after {
    left: 0;
    right: initial;
}


.custom-select[multiple], .custom-select[size]:not([size="1"]) {
    padding-right: 0;
    padding-left: 0.75rem;
}

.nav {
    padding-right: 0;
}

.navbar-brand {
    margin-left: 1rem;
    margin-right: 0;
}

.navbar-nav {
    padding-right: 0;
}

@media (min-width: 576px) {
    .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        left: 0;
        right: auto;
    }
}

@media (min-width: 768px) {
    .navbar-expand-md .navbar-nav .dropdown-menu-right {
        left: 0;
        right: auto;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        left: 0;
        right: auto;
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        left: 0;
        right: auto;
    }
}

.navbar-expand .navbar-nav .dropdown-menu-right {
    left: 0;
    right: auto;
}

.card-link + .card-link {
    margin-right: 1.25rem;
    margin-left: 0;
}

.card-footer:last-child {
    border-radius: 0 calc(0.25rem - 1px) calc(0.25rem - 1px) 0;
}

@media (min-width: 576px) {
    .card-group > .card + .card {
      margin-right: 0;
      border-right: 0;
    }
    .card-group > .card:first-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .card-group > .card:first-child .card-img-top,
    .card-group > .card:first-child .card-header {
      border-top-left-radius: 0;
    }
    .card-group > .card:first-child .card-img-bottom,
    .card-group > .card:first-child .card-footer {
      border-bottom-left-radius: 0;
    }
    .card-group > .card:last-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .card-group > .card:last-child .card-img-top,
    .card-group > .card:last-child .card-header {
      border-top-right-radius: 0;
    }
    .card-group > .card:last-child .card-img-bottom,
    .card-group > .card:last-child .card-footer {
      border-bottom-right-radius: 0;
    }
}

.breadcrumb-item + .breadcrumb-item {
  padding-right: 0.5rem;
  padding-left: 0;
}
  
.pagination {
    padding-right: 0;
}

.page-item:first-child .page-link {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-top-left-radius: initial;
    border-bottom-left-radius: initial;
}

.page-item:last-child .page-link {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: initial;
    border-bottom-right-radius: initial;
}

.page-link {
    margin-right: -1px;
    margin-left: 0px;
}

.pagination-lg .page-item:first-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.pagination-lg .page-item:last-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.pagination-sm .page-item:first-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.pagination-sm .page-item:last-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}


.alert-dismissible .close {
    left: 0;
    right: initial;
}


.list-group {
    padding-right: 0;
}


.modal-header .close {
    margin: -15px auto -15px -15px;
}

.modal-footer > :not(:first-child) {
    margin-right: .25rem;
    margin-left: 0;
}

.modal-footer > :not(:last-child) {
    margin-left: .25rem;
    margin-right: 0;
}

.tooltip {
    text-align: right;
}

.tooltip.bs-tooltip-top .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    margin-right: -0.4rem;
    margin-left: 0;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    right: 0;
    left: initial;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
    left: 0;
    right: initial;
    border-left-color: #000;
    border-right-color: initial;
}

.tooltip.bs-tooltip-bottom .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    margin-right: -0.4rem;
    margin-left: 0;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    left: 0;
    right: initial;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
    left: 0;
    right: initial;
    border-right-color: #000;
    border-left-color: initial;
}

.popover {
    right: 0;
    left: initial;
    text-align: right;
}

.popover.bs-popover-right, .popover.bs-popover-auto[x-placement^="right"] {
    margin-right: 0.5rem;
    margin-left: 0;
}

.bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    right: calc((0.5rem + 1px) * -1);;
    left: initial;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: initial;
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25);
    border-right-color: initial;
}

.bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
    right: 1px;
    left: initial;
    border-left-color: #fff;
    border-right-color: initial;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    right: 50%;
    left: initial;
    margin-right: -0.5rem;
    margin-left: 0px;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
    margin-left: 0.5rem;
    margin-right: 0;
}

.bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    right: initial;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    left: 0;
    right: initial;
    border-right-color: rgba(0, 0, 0, 0.25);
    border-left-color: initial;
}

.bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
    left: 1px;
    right: initial;
    border-right-color: #fff;
    border-left-color: initial;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.carousel-item-next,
.active.carousel-item-left {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
}

.carousel-item-next,
.active.carousel-item-right {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-next,
    .active.carousel-item-left {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
    .carousel-item-next,
    .active.carousel-item-right {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.carousel-item-prev,
.active.carousel-item-left {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
}

.carousel-item-prev,
.active.carousel-item-left {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-prev,
    .active.carousel-item-right {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
    .active.carousel-item-left {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.carousel-control-prev {
    right: 0;
    left: initial;
}

.carousel-control-next {
    left: 0;
    right: initial;
}

.carousel-indicators {
    padding-right: 0;
}
.carousel-indicators li::before {
    right: 0;
    left: initial;
}

.carousel-indicators li::after {
    right: 0;
    left: initial;
}

.border-right-0 {
    border-left: 0 !important;
}

.border-left-0 {
    border-right: 0 !important;
}

.rounded-right {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    right: 0;
    left: initial;
}

.float-left {
    float: right !important;
}

.float-right {
    float: left !important;
}

@media (min-width: 576px) {
    .float-sm-left {
        float: right !important;
    }
    .float-sm-right {
        float: left !important;
    }
}

@media (min-width: 768px) {
    .float-md-left {
        float: right !important;
    }
    .float-md-right {
        float: left !important;
    }
}

@media (min-width: 992px) {
    .float-lg-left {
        float: right !important;
    }
    .float-lg-right {
        float: left !important;
    }
}

@media (min-width: 1200px) {
    .float-xl-left {
        float: right !important;
    }
    .float-xl-right {
        float: left !important;
    }
}

.mr-0,
.mx-0 {
    margin-left: 0 !important;
}

.ml-0,
.mx-0 {
    margin-right: 0 !important;
}

.mr-1,
.mx-1 {
    margin-left: 0.25rem !important;
}

.ml-1,
.mx-1 {
    margin-right: 0.25rem !important;
}


.mr-2,
.mx-2 {
    margin-left: 0.5rem !important;
}

.ml-2,
.mx-2 {
    margin-right: 0.5rem !important;
}

.mr-3,
.mx-3 {
    margin-left: 1rem !important;
}

.ml-3,
.mx-3 {
    margin-right: 1rem !important;
}

.mr-4,
.mx-4 {
    margin-left: 1.5rem !important;
}

.ml-4,
.mx-4 {
    margin-right: 1.5rem !important;
}

.mr-5,
.mx-5 {
    margin-left: 3rem !important;
}

.ml-5,
.mx-5 {
    margin-right: 3rem !important;
}

.pr-0,
.px-0 {
    padding-left: 0 !important;
}

.pl-0,
.px-0 {
    padding-right: 0 !important;
}

.pr-1,
.px-1 {
    padding-left: 0.25rem !important;
}

.pl-1,
.px-1 {
    padding-right: 0.25rem !important;
}

.pr-2,
.px-2 {
    padding-left: 0.5rem !important;
}

.pl-2,
.px-2 {
    padding-right: 0.5rem !important;
}

.pr-3,
.px-3 {
    padding-left: 1rem !important;
}

.pl-3,
.px-3 {
    padding-right: 1rem !important;
}

.pr-4,
.px-4 {
    padding-left: 1.5rem !important;
    padding-right: 0 !important;
}

.pl-4,
.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 0 !important;
}

.pr-5,
.px-5 {
    padding-left: 3rem !important;
    padding-right: 0 !important;
}

.pl-5,
.px-5 {
    padding-right: 3rem !important;
    padding-left: 0 !important;
}

.mr-auto,
.mx-auto {
    margin-left: auto !important;
    margin-right: 0 !important;
}

.ml-auto,
.mx-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
}

@media (min-width: 576px) {
    .mr-sm-0,
    .mx-sm-0 {
        margin-left: 0 !important;
    }
    .ml-sm-0,
    .mx-sm-0 {
        margin-right: 0 !important;
    }
    .mr-sm-1,
    .mx-sm-1 {
        margin-left: 0.25rem !important;
        margin-right: 0 !important;
    }
    .ml-sm-1,
    .mx-sm-1 {
        margin-right: 0.25rem !important;
        margin-left: 0 !important;
    }
    .mr-sm-2,
    .mx-sm-2 {
        margin-left: 0.5rem !important;
        margin-right: 0 !important;
    }
    .ml-sm-2,
    .mx-sm-2 {
        margin-right: 0.5rem !important;
        margin-left: 0 !important;
    }
    .mr-sm-3,
    .mx-sm-3 {
        margin-left: 1rem !important;
        margin-right: 0 !important;
    }
    .ml-sm-3,
    .mx-sm-3 {
        margin-right: 1rem !important;
        margin-left: 0 !important;
    }
    .mr-sm-4,
    .mx-sm-4 {
        margin-left: 1.5rem !important;
        margin-right: 0 !important;
    }
    .ml-sm-4,
    .mx-sm-4 {
        margin-right: 1.5rem !important;
        margin-left: 0 !important;
    }
    .mr-sm-5,
    .mx-sm-5 {
        margin-left: 3rem !important;
        margin-right: 0 !important;
    }
    .ml-sm-5,
    .mx-sm-5 {
        margin-right: 3rem !important;
        margin-left: 0 !important;
    }
    .pr-sm-0,
    .px-sm-0 {
        padding-left: 0 !important;
    }
    .pl-sm-0,
    .px-sm-0 {
        padding-right: 0 !important;
    }
    .pt-sm-1,
    .py-sm-1 {
        padding-right: 0.25rem !important;
        padding-top: 0 !important;
    }
    .pr-sm-1,
    .px-sm-1 {
        padding-top: 0.25rem !important;
        padding-right: 0 !important;
    }
    .pl-sm-1,
    .px-sm-1 {
        padding-left: 0.25rem !important;
    }
    .pr-sm-2,
    .px-sm-2 {
        padding-left: 0.5rem !important;
        padding-right: 0 !important;
    }
    .pl-sm-2,
    .px-sm-2 {
        padding-right: 0.5rem !important;
        padding-left: 0 !important;
    }
    .pr-sm-3,
    .px-sm-3 {
        padding-left: 1rem !important;
        padding-right: 0 !important;
    }
    .pl-sm-3,
    .px-sm-3 {
        padding-right: 1rem !important;
        padding-left: 0 !important;
    }
    .pr-sm-4,
    .px-sm-4 {
        padding-left: 1.5rem !important;
        padding-right: 0 !important;
    }
    .pl-sm-4,
    .px-sm-4 {
        padding-right: 1.5rem !important;
        padding-left: 0 !important;
    }
    .pr-sm-5,
    .px-sm-5 {
        padding-left: 3rem !important;
        padding-right: 0 !important;
    }
    .pl-sm-5,
    .px-sm-5 {
        padding-right: 3rem !important;
        padding-left: 0 !important;
    }
    .mr-sm-auto,
    .mx-sm-auto {
        margin-left: auto !important;
        margin-right: 0 !important;
    }
    .ml-sm-auto,
    .mx-sm-auto {
        margin-right: auto !important;
        margin-left: 0 !important;
    }
}

@media (min-width: 768px) {
    .mr-md-0,
    .mx-md-0 {
        margin-left: 0 !important;
    }
    .ml-md-0,
    .mx-md-0 {
        margin-right: 0 !important;
    }
    .mr-md-1,
    .mx-md-1 {
        margin-left: 0.25rem !important;
        margin-right: 0 !important;
    }
    .ml-md-1,
    .mx-md-1 {
        margin-right: 0.25rem !important;
        margin-left: 0 !important;
    }
    .mr-md-2,
    .mx-md-2 {
        margin-left: 0.5rem !important;
        margin-right: 0 !important;
    }
    .ml-md-2,
    .mx-md-2 {
        margin-right: 0.5rem !important;
        margin-left: 0 !important;
    }
    .mr-md-3,
    .mx-md-3 {
        margin-left: 1rem !important;
        margin-right: 0 !important;
    }
    .ml-md-3,
    .mx-md-3 {
        margin-right: 1rem !important;
        margin-left: 0 !important;
    }
    .mr-md-4,
    .mx-md-4 {
        margin-left: 1.5rem !important;
        margin-right: 0 !important;
    }
    .ml-md-4,
    .mx-md-4 {
        margin-right: 1.5rem !important;
        margin-left: 0 !important;
    }
    .mr-md-5,
    .mx-md-5 {
        margin-left: 3rem !important;
        margin-right: 0 !important;
    }
    .ml-md-5,
    .mx-md-5 {
        margin-right: 3rem !important;
        margin-left: 0 !important;
    }
    .pr-md-0,
    .px-md-0 {
        padding-left: 0 !important;
    }
    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0 !important;
    }
    .pl-md-0,
    .px-md-0 {
        padding-right: 0 !important;
    }
    .pr-md-1,
    .px-md-1 {
        padding-left: 0.25re !important;
        padding-right: 0 !important;
    }
    .pl-md-1,
    .px-md-1 {
        padding-right: 0.25rem !important;
        padding-left: 0 !important;
    }
    .pr-md-2,
    .px-md-2 {
        padding-left: 0.5rem !important;
        padding-right: 0 !important;
    }
    .pl-md-2,
    .px-md-2 {
        padding-left: 0.5rem !important;
        padding-right: 0 !important;
    }
    .pr-md-3,
    .px-md-3 {
        padding-left: 1rem !important;
        padding-right: 0 !important;
    }
    .pl-md-3,
    .px-md-3 {
        padding-right: 1rem !important;
        padding-left: 0 !important;
    }
    .pr-md-4,
    .px-md-4 {
        padding-left: 1.5rem !important;
        padding-right: 0 !important;
    }
    .pl-md-4,
    .px-md-4 {
        padding-right: 1.5rem !important;
        padding-left: 0 !important;
    }
    .pr-md-5,
    .px-md-5 {
        padding-left: 3rem !important;
        padding-right: 0 !important;
    }
    .pl-md-5,
    .px-md-5 {
        padding-right: 3rem !important;
        padding-left: 0 !important;
    }
    .mr-md-auto,
    .mx-md-auto {
        margin-left: auto !important;
        margin-right: 0 !important;
    }
    .ml-md-auto,
    .mx-md-auto {
        margin-right: auto !important;
        margin-left: 0 !important;
    }
}

@media (min-width: 992px) {
    .mr-lg-0,
    .mx-lg-0 {
        margin-left: 0 !important;
    }
    .ml-lg-0,
    .mx-lg-0 {
        margin-right: 0 !important;
    }
    .mr-lg-1,
    .mx-lg-1 {
        margin-left: 0.25rem !important;
        margin-right: 0 !important;
    }
    .ml-lg-1,
    .mx-lg-1 {
        margin-left: 0.25rem !important;
        margin-right: 0 !important;
    }
    .mr-lg-2,
    .mx-lg-2 {
        margin-left: 0.5rem !important;
        margin-right: 0 !important;
    }
    .ml-lg-2,
    .mx-lg-2 {
        margin-right: 0.5rem !important;
        margin-left: 0 !important;
    }
    .mr-lg-3,
    .mx-lg-3 {
        margin-left: 1rem !important;
        margin-right: 0 !important;
    }
    .ml-lg-3,
    .mx-lg-3 {
        margin-right: 1rem !important;
        margin-left: 0 !important;
    }
    .mr-lg-4,
    .mx-lg-4 {
        margin-left: 1.5rem !important;
        margin-right: 0 !important;
    }
    .ml-lg-4,
    .mx-lg-4 {
        margin-right: 1.5rem !important;
        margin-left: 0 !important;
    }
    .mr-lg-5,
    .mx-lg-5 {
        margin-left: 3rem !important;
        margin-right: 0 !important;
    }
    .ml-lg-5,
    .mx-lg-5 {
        margin-right: 3rem !important;
        margin-left: 0 !important;
    }
    .pr-lg-0,
    .px-lg-0 {
        padding-left: 0 !important;
    }
    .pl-lg-0,
    .px-lg-0 {
        padding-right: 0 !important;
    }
    .pr-lg-1,
    .px-lg-1 {
        padding-left: 0.25rem !important;
        padding-right: 0 !important;
    }
    .pl-lg-1,
    .px-lg-1 {
        padding-right: 0.25rem !important;
        padding-left: 0 !important;
    }
    .pr-lg-2,
    .px-lg-2 {
        padding-left: 0.5rem !important;
        padding-right: 0 !important;
    }
    .pl-lg-2,
    .px-lg-2 {
        padding-right: 0.5rem !important;
        padding-left: 0 !important;
    }
    .pr-lg-3,
    .px-lg-3 {
        padding-left: 1rem !important;
        padding-right: 0 !important;
    }
    .pl-lg-3,
    .px-lg-3 {
        padding-right: 1rem !important;
        padding-left: 0 !important;
    }
    .pr-lg-4,
    .px-lg-4 {
        padding-left: 1.5rem !important;
        padding-right: 0 !important;
    }
    .pl-lg-4,
    .px-lg-4 {
        padding-right: 1.5rem !important;
        padding-left: 0 !important;
    }
    .pr-lg-5,
    .px-lg-5 {
        padding-left: 3rem !important;
        padding-right: 0 !important;
    }
    .pl-lg-5,
    .px-lg-5 {
        padding-right: 3rem !important;
        padding-left: 0 !important;
    }
    .mr-lg-auto,
    .mx-lg-auto {
        margin-left: auto !important;
        margin-right: 0 !important;
    }
    .ml-lg-auto,
    .mx-lg-auto {
        margin-right: auto !important;
        margin-left: 0 !important;
    }
}

@media (min-width: 1200px) {
    .mr-xl-0,
    .mx-xl-0 {
        margin-left: 0 !important;
    }
    .ml-xl-0,
    .mx-xl-0 {
        margin-right: 0 !important;
    }
    .mr-xl-1,
    .mx-xl-1 {
        margin-left: 0.25rem !important;
        margin-right: 0 !important;
    }
    .ml-xl-1,
    .mx-xl-1 {
        margin-right: 0.25rem !important;
        margin-left: 0 !important;
    }
    .mr-xl-2,
    .mx-xl-2 {
        margin-left: 0.5rem !important;
        margin-right: 0 !important;
    }
    .ml-xl-2,
    .mx-xl-2 {
        margin-right: 0.5rem !important;
        margin-left: 0 !important;
    }
    .mr-xl-3,
    .mx-xl-3 {
        margin-left: 1rem !important;
        margin-right: 0 !important;
    }
    .ml-xl-3,
    .mx-xl-3 {
        margin-right: 1rem !important;
        margin-left: 0 !important;
    }
    .mr-xl-4,
    .mx-xl-4 {
        margin-left: 1.5rem !important;
        margin-right: 0 !important;
    }
    .ml-xl-4,
    .mx-xl-4 {
        margin-right: 1.5rem !important;
        margin-left: 0 !important;
    }
    .mr-xl-5,
    .mx-xl-5 {
        margin-left: 3rem !important;
        margin-right: 0 !important;
    }
    .ml-xl-5,
    .mx-xl-5 {
        margin-right: 3rem !important;
        margin-left: 0 !important;
    }
    .pr-xl-0,
    .px-xl-0 {
        padding-left: 0 !important;
    }
    .pl-xl-0,
    .px-xl-0 {
        padding-right: 0 !important;
    }
    .pr-xl-1,
    .px-xl-1 {
        padding-left: 0.25rem !important;
        padding-right: 0 !important;
    }
    .pl-xl-1,
    .px-xl-1 {
        padding-right: 0.25rem !important;
        padding-left: 0 !important;
    }
    .pr-xl-2,
    .px-xl-2 {
        padding-left: 0.5rem !important;
        padding-right: 0 !important;
    }
    .pl-xl-2,
    .px-xl-2 {
        padding-right: 0.5rem !important;
        padding-left: 0 !important;
    }
    .pr-xl-3,
    .px-xl-3 {
        padding-left: 1rem !important;
        padding-right: 0 !important;
    }
    .pl-xl-3,
    .px-xl-3 {
        padding-right: 1rem !important;
        padding-left: 0 !important;
    }
    .pr-xl-4,
    .px-xl-4 {
        padding-left: 1.5rem !important;
        padding-right: 0 !important;
    }
    .pl-xl-4,
    .px-xl-4 {
        padding-right: 1.5rem !important;
        padding-left: 0 !important;
    }
    .pr-xl-5,
    .px-xl-5 {
        padding-right: 1.5rem !important;
        padding-left: 0 !important;
    }
    .pl-xl-5,
    .px-xl-5 {
        padding-right: 3rem !important;
        padding-left: 0 !important;
    }
    .mr-xl-auto,
    .mx-xl-auto {
        margin-left: auto !important;
        margin-right: 0 !important;
    }
    .ml-xl-auto,
    .mx-xl-auto {
        margin-right: auto !important;
        margin-left: 0 !important;
    }
}

.text-left {
    text-align: right !important;
}

.text-right {
    text-align: left !important;
}

@media (min-width: 576px) {
    .text-sm-left {
        text-align: right !important;
    }
    .text-sm-right {
        text-align: left !important;
    }
}

@media (min-width: 768px) {
    .text-md-left {
        text-align: right !important;
    }
    .text-md-right {
        text-align: left !important;
    }
}

@media (min-width: 992px) {
    .text-lg-left {
        text-align: right !important;
    }
    .text-lg-right {
        text-align: left !important;
    }
}

@media (min-width: 1200px) {
    .text-xl-left {
        text-align: right !important;
    }
    .text-xl-right {
        text-align: left !important;
    }
}
